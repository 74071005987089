
            @import 'src/styles/mixins.scss';
        
.backdrop {
    background-color: rgba(black, 0.8);
    @apply fixed top-0 left-0 bottom-0
        right-0 z-100 h-full;
}

.wrapper {
    @apply fixed top-0 left-0 bottom-0 right-0 z-1000 h-full py-9 lg:py-6 overflow-auto items-start;
}

.close-button {
    @apply w-12 h-12 absolute top-0 right-0 z-10 bg-transparent border-0 outline-none cursor-pointer flex items-center justify-center;
    svg {
        width: 17px;
        height: 17px;
        fill: black;
        @include lg {
            width: 22px;
            height: 22px;
            fill: white;
        }
    }
    @include lg {
        transform: translateX(100%);
        svg,
        path {
            fill: white;
        }
    }
}

.modal {
    background-color: #fff;
    @apply mx-auto relative overflow-hidden lg:overflow-visible z-200;
    .body {
        @apply pointer-events-auto;
    }
}

.header {
    color: black;
    @apply py-5 px-4 lg:px-7 text-2xl lg:text-2xl font-bold;
}

.disclaimerModal.modal {
    height: 500px;
}
